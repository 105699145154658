@media only screen and (min-width: 991px) and (max-width: 2000px) {
    .modal-content {
        width: 600px !important;
    }

    .modal-dialog {
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
    }

}

.modal-header {
    border-bottom: 1px solid lightgray !important;
}

.modal-open {
    overflow: hidden !important;
}


