@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

:root {
    --mainFont: 'Poppins', sans-serif;
}

.navbar {
    background: rgba(255, 255, 255, .9);
    /* background-color: #eaf0f9; */
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;

}

.nav-container {
    display: flex;
    width: 100%;
    /* max-width: 1500px; */
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #3b60c9; */
}

.nav-icon .fa {
    color: #000;
}

.nav-logo {
    /* color: #f5b921; */
    align-items: left;
    margin-left: 50px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: left;
    margin-right: 2rem;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
}

.nav-item .sub-menu {
    list-style: none;
    text-align: left;
    margin-right: 2rem;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;
}

.nav-item {
    line-height: 20px;
    margin-right: 1rem;
    position: relative;

}

.nav-item:after {
    content: "";
    display: block;
    height: 2px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: var(--maincolor) !important;
}

.nav-item.active {
    color: red;
    /* border: 1px solid #fff; */
}

.nav-links {
    color: rgba(10, 9, 102, 1);
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: var(--mainFont);
    font-weight: 500;
    font-size: 16px !important;
    /* border-bottom: .5px solid transparent; */
}

.nav-links span {
    margin-left: 15px;
}

.nav-links i {
    color: #d6304d;
}

.nav-item:hover .nav-links i {
    color: #3b60c9 !important;
}

.angledown_icon {
    position: relative;
}

.angledown_icon .fa-angle-down {
    text-align: end !important;
    color: #000;
    position: relative;
    left: 60%;
}


.fa-code {
    margin-left: 1rem;
}

.nav-icon {
    display: none;
}


@media screen and (max-width:991px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: .5px solid lightgray;
        position: absolute;
        top: 110px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        /* padding-bottom: 10px; */
    }

    .nav-menu.active {
        background: #fff;
        /* background: #eaf0f9; */
        /* background-color: rgba(10, 9, 102, .9); */
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        color: red;
        border-top: .5px solid lightgray;
    }

    .nav-menu.active .navbar {
        background-color: rgba(10, 9, 102, .9) !important;
    }

    .nav-item .active {
        color: #000;
        border: none;
    }

    .nav-links {
        padding: 13px;
        width: 100%;
        font-size: 15px;
        display: table;
        border-bottom: .5px solid lightgray;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 3rem;
        cursor: pointer;
        color: #000;
    }

    .nav-item .sub-menu {
        background-color: transparent;
        width: 100%;
        opacity: 1;
        transition: all 0.3s ease !important;
        padding-bottom: 10px;
        padding-left: 60px;
        text-align: left;
        padding-top: 20px;
        min-height: 80px !important;
    }

    .nav-item .sub-menu .submenu-link {
        padding: 9px;
        width: 100%;
        display: table;
        text-transform: capitalize;
        font-family: var(--mainFont);
        font-weight: 500;
    }

    .nav-item .nav-links .sub-menu .submenu-link a {
        font-size: 14px;
        color: rgba(10, 9, 102, 1) !important;
    }

    .sticky-menu {
        position: relative;
        background-color: rgba(255, 255, 255, .5);
        /* Set your menu background color */
        transition: background-color 0.3s ease;
        padding: 0px;
    }

    .sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Optional: Add a box shadow when sticky */
        z-index: 1000;
        background-color: rgba(255, 255, 255, .5) !important;
        /* Set the sticky menu background color */
    }

    #slider-area .social-networks-icon {
        display: block;
        position: absolute;
        left: 23px;
        top: 8%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
    }

}

/* 
@media screen and (min-width: 100px) and (max-width: 440px) {
    .sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Optional: Add a box shadow when sticky 
        z-index: 100;
        background-color: rgba(255, 255, 255, .5) !important;
    }
} */

@media screen and (min-width: 200px) and (max-width: 300px) {
    .schoolname_txt h5 a {
        color: #d6304d;
        font-weight: 900;
        font-size: 21px;
        font-family: var(--mainFont);
    }

    .angledown_icon .fa-angle-down {
        text-align: end !important;
        color: #000;
        position: relative;
        left: 39%;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .schoolname_txt h5 a {
        color: #d6304d;
        font-weight: 900;
        font-size: 30px;
        font-family: var(--mainFont);
    }

    .angledown_icon .fa-angle-down {
        text-align: end !important;
        color: #000;
        position: relative;
        left: 45%;
    }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
    .schoolname_txt h5 a {
        color: #d6304d;
        font-weight: 900;
        font-size: 35px;
        font-family: var(--mainFont);
    }

    .angledown_icon .fa-angle-down {
        text-align: end !important;
        color: #000;
        position: relative;
        left: 60%;
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .schoolname_txt h5 a {
        color: #d6304d;
        font-weight: 900;
        font-size: 35px;
        font-family: var(--mainFont);
    }

    .angledown_icon .fa-angle-down {
        text-align: end !important;
        color: #000;
        position: relative;
        left: 70%;
    }
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 300px) and (max-width: 786px) {

    .nav-menu {
        display: flex
;
        flex-direction: column;
        width: 100%;
        /* border-top: .5px solid lightgray; */
        position: absolute;
        top: 75px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        /* padding-bottom: 10px; */
    }
}
@media screen and (min-width: 786px) and (max-width: 991px) {

    .nav-menu {
        display: flex
;
        flex-direction: column;
        width: 100%;
        /* border-top: .5px solid lightgray; */
        position: absolute;
        top: 98px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        /* padding-bottom: 10px; */
    }
}